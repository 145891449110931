.h-red{ color: $header-red; }
.h-orange{ color: $header-orange; }
.h-dark-red{ color: $dark-purple-3; }

.color-primary{ color: $primary-text; }
.color-white{ color: $white-text; }
.color-gray{ color: $gray-text; }
.upper { text-transform: uppercase; }
.italic { font-style: italic; }
.pointer { cursor: pointer; }

// Font sizes and weights
.lighter{ font-weight: lighter; }
.normal{ font-weight: normal; }
.w500{ font-weight: 500; }
.w600{ font-weight: 600; }
.w700{ font-weight: 700; }

.d-in{ display: inline; }
.d-ib{ display: inline-block; }
.d-bl{ display: block; }
.d-un{ display: unset; }

.center{ text-align: center; }
.vcc { display: flex; align-items: center; }
.no-pb { padding-bottom: 0; }
.no-pt { padding-top: 0; }
.no-mb { margin-bottom: 0; }
.no-mt { margin-top: 0; }

.mt-3 { margin-top: $mp3; }
.ml-3 { margin-left: $mp3; }
.mr-3 { margin-right: $mp3; }

.mb-sh { margin-bottom: 0.75em; } // standard header
.mb-pt2em { margin-bottom: 0.2em; }
.mb-pt5em { margin-bottom: 0.5em; }
.mb-1em { margin-bottom: 1em; }
.mb-2em { margin-bottom: 2em; }
.mt-pt2em { margin-top: 0.2em; }
.mt-pt5em { margin-top: 0.5em; }
.mt-1em { margin-top: 1em; }
.mt-2em { margin-top: 2em; }