@import "../../variables";

#filming-style-component {
  color: $white-text;
  // border-top: 1px solid gray;
  .page-container {
    padding: 1em;
    // position: absolute;
    // background-color: transparent;
    background: $really-dark-purple-1;
    // background: linear-gradient(#35061e 0%, $red-purple-2 5%);
    background: radial-gradient(ellipse at center bottom, $red-purple-1 70%, $dark-purple-1);
  }

  .reality-container{
    .reality{
      padding: 1em 0;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      &>*{
        flex-basis: 100%;
      }
      .sb-img>img{ width: 100%; }
      ul {
        width: 80%;
        flex-basis: 100%;
      }

      .before-label { 
        margin-bottom: 1em;
        width: 100%;
        text-align: center;
      }
      // &:first-child{
      //   .before-label { 
      //     margin-top: 200px;
      //   }
      // }
    }
    .reality-1{
      background-color: $red-purple-1;
    }
    .reality-2{
      background-color: $dark-purple-1;
    }
    .reality-3{
      background-color: $really-dark-purple-1;
    }
  }
  .after-label { margin: 1em 0; display: none; }
  
  @media screen and (min-width: $break-point-400) {
    .reality-container{
      flex-basis: row;
      justify-content: center;
      .reality ul{
        width: 50%;
      }
    }
  }
  
  @media screen and (min-width: $break-point-1024) {
    // .page-container {
    //   position: absolute;
    //   background-color: transparent;
    // }
    // border-top: 1px solid gray;
    h1{
      text-align: center;
    }
    .reality-container{
      display: flex;
      flex-flow: row nowrap;
      &>*{
        width: auto;
        flex-basis: 33%;
      }
      .reality-1 { flex-basis: 34%; }
      .reality{
        padding: 3em 0;
        // padding: 20vw 0 8em;
        .sb-img{ padding: 0 1em; }
        ul{ width: 80%;}
      }
    }
    .page-container{
      background: linear-gradient(90deg, $red-purple-1, $really-dark-purple-1);
      .subtitle {
        font-size: 2.3vw;
      }
    }
    .floating-items{
      position: absolute;
      background-color: unset;
      z-index: 1;
    }
    .before-label { display: none; }
    .after-label { display: block; }
  }

}