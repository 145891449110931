@import "variables.scss";
@import "helpers.scss";

@font-face {
  font-family: "BasisGrotesquePro";
  src: url("./resources/fonts/BasisGrotesquePro/BasisGrotesquePro-Bold.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "BasisGrotesquePro";
  src: url("./resources/fonts/BasisGrotesquePro/BasisGrotesquePro-Light.otf") format("opentype");
  font-weight: 100;
}
@font-face {
  font-family: "BasisGrotesquePro";
  src: url("./resources/fonts/BasisGrotesquePro/basis_grotesque_pro_medium.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "BasisGrotesquePro";
  src: url("./resources/fonts/BasisGrotesquePro/basis_grotesque_pro_medium_italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

#App {
  position: relative;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body {
  font-family: 'BasisGrotesquePro', sans-serif;
  font-size: $fontSize1;

  color: $off-white-yellow;
  min-width: $min-site-width;
  background-color: #0d0102;
}
h1 { font-size: 1.3em; }
h2 { font-size: 1.1em; }
h3 { font-size: 1.0em; }
h4 { font-size: 0.85em; }
h5 { font-size: 0.7em; }

h1, h2, h3, h4, h5 {
  letter-spacing: 0.05em;
}
a {
  // color: $header-red;
  text-decoration: none;
  &:hover{ color: $header-orange };
}
p {
  font-weight: lighter;
  padding-bottom: 1em;
}

// Layouts
.page-container { padding: 1em 1em 3em 1em; }
    
@media screen and (min-width:$break-point-400){
  body {
    font-size: $fontSize2;
  }
}
@media screen and (min-width:$break-point-800){
  body {
    font-size: $fontSize3;
  }
}
@media screen and (min-width:$break-point-1024){
  body {
    font-size: $fontSize4;
  }
}
@media screen and (min-width:$break-point-1400){
  body {
    font-size: $fontSize5;
  }
}
@media screen and (min-width:$break-point-1920){
  body {
    font-size: $dFontSize1;
  }
}