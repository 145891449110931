@import "../../variables.scss";

#themes-component {
  background-color: rgba(13, 1, 2, 0.9);
  background-image: url('../../images/backgrounds/pg5.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // .bg-overlay{
  //   color: $primary-text;
  //   background-color: rgba(0,0,0,0.1);
  //   // backdrop-filter: blur(8px);
  // }

  h2 {
    text-align: center;
    letter-spacing: 0.2em;
  }
  .theme-item {
    margin-bottom: 1rem;
    &>img {
      width: 100%;
    }
  }

  .container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    >*{
      flex-basis: 100%;
    }
    @media screen and (min-width: $break-point-800) {
      >*{
        flex-basis: 48%;
        margin-bottom: 2rem;
      }
      h2 { text-align: unset; }
    }
    // @media screen and (min-width: $break-point-1024) {
    //   >*{
    //     flex-basis: 40%;
    //     margin-bottom: 8rem;
    //   }
    // }
    // @media screen and (min-width: $break-point-1920) {
    //   >*{
    //     flex-basis: 35vw;
    //   }
    // }
  }
}