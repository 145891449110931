@import "../../variables.scss";

#directors-statement-component {
  background: $dark-purple-1;
  background: linear-gradient(163deg, $red-purple-2, $dark-purple-1 45%);

  background-image: url('../../images/backgrounds/pg6.jpg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  .bg-overlay{
    // background: linear-gradient(163deg, rgba(146, 10, 11, 0.75), rgba(89, 13, 33, 0.75) 45%);
    background: linear-gradient(163deg, rgba(240, 26, 26, 0.3), rgba(32, 4, 11, 0.3) 45%);
    // backdrop-filter: blur(8px);
  }

  text-align: center;

  .attribution {
    font-style: italic;
    font-size: 0.7em;
  }

  .layout-container {
    display: flex;
    flex-flow: column;
  }

  h1 {
    order: 1;
  }

  .profile-pic {
    order: 1;
    display: flex;
    justify-content: center;
    >.img{
      max-width: 500px;
      >img {width: 100%;}
    }
    margin-bottom: $mp3;
  }

  .statement {
    order: 2;
    font-weight: lighter;
  }

  .stats {
    order: 3;
    text-align: left;
    align-self: center;
    padding: 1em 0 2em;
    color: $white-text;
    font-size: 0.9em;
    width: fit-content;
    span {
      margin-left: 0.6rem;
    }
  }

  .story-boards {
    order: 2;
    .sb-img > img {
      width: 100%;
    }
  }

  @media screen and (min-width: $break-point-530) {
    .profile-pic { margin-bottom: $mp5; }
  }

  @media screen and (min-width: $break-point-800) {
    .layout-container {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto 1fr repeat(2, 0.5fr) auto;
      grid-template-areas:
        "header header"
        "statement pic"
        "statement stats"
        "statement stats"
        "storyboard storyboard"
      ;
      justify-items: center;
      align-items: start;
    }
    h1 {
      grid-area: header;
    }
    .statement {
      grid-area: statement;
      text-align: left;
      padding-right: $border-padding2;
    }
    .profile-pic {
      grid-area: pic;
    }
    .stats {
      font-size: 0.8em;
      grid-area: stats;
      align-self: unset;
      margin-top: 10px;
    }
    .story-boards {
      grid-area: storyboard;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      >*{
        flex-basis: 49%;
        &.attribution{
          flex-basis: 98%;
          justify-self: right;
          text-align: right;
        }
      }
    }
  }

  @media screen and (min-width: $break-point-1024) {
    .layout-container {
      grid-template-columns: 3fr 1fr;
      grid-auto-rows: auto;
      grid-template-rows: repeat(4, auto);
      grid-template-areas:
        "header pic"
        "statement pic"
        "statement stats"
        "storyboard storyboard"
      ;
    }
    h1 {
      justify-self: left;
    }
    .profile-pic {
      margin-top: 2em;
    }
    .statement {
      padding-right: 3em;
    }
    .story-boards>*{
      flex-basis: 24%;
    }
  }
}