@import "../../variables.scss";

#home-component{
  height: calc((1514 / 1920) * 100vw);
  min-height: calc((1514 / 1920) * $min-site-width);
  .logo-container {
    background: url('../../images/pages/home/home-bg.jpg') top no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    .logo-image {
      img { width: 100%; }
    }
    .delicate{
      width: 55vw;
    }
    .arch{
      width: 35vw;
    }

  }
  @media screen and (min-width: $break-point-530) {
    .logo-container {
      flex-flow: row wrap;
      align-items: flex-end;
      justify-content: center;
      .delicate{
        width: 48vw;
      }
      .arch{
        width: 30vw;
      }
    }
  }
 
  @media screen and (min-width: $break-point-2500) {
    margin-top: calc(-1 * (0.21 * 100vw));
    .logo-container {
      flex-flow: column wrap;
      align-items: center;
      justify-content: flex-end;
      .delicate{
        width: 48vw;
        align-self: flex-start;
        justify-self: left;
        margin-left: 8em;
      }
      .arch{
        width: 30vw;
        margin-top: 3em;
        margin-left: 30em;
        margin-bottom: 3.5em;
      }
    }
  }
}