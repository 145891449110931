@import "../../variables";

html.suppress-events {
  height: 100vh;
  overflow-y: hidden;
}
#Navs {
  $top-nav-bar-h: 80px;
  $menu-margins: 1rem;
  // $menu-break-point: 1420px;
  $menu-break-point: 1610px;
  position: relative;
  #overlay {
    position: fixed;
    z-index: 301;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(20,20,20,0.6);
    background-color: rgb(89,14,34,0.6);;
    backdrop-filter: blur(6px);
    // pointer-events: none;
  }

  .navbar-item{
    font-size: calc($top-nav-bar-h * 0.3);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 14px;
    color: white;
    &:hover {
      cursor: pointer;
      background-color: rgba(255,255,255,0.2);
    }
    &.support{
      color: $support;
      svg{
        margin-right: $mp3;
      }
    }
  }

  #top-nav-bar{
    z-index: 201;
    // background-color: green;
    background-color: rgba(50,50,50,0.6);
    backdrop-filter: blur(16px);
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    height: $top-nav-bar-h;

    display: none;
    justify-items: center;
    align-items: center;
    justify-content: space-evenly;

    #logo-btn{
      background-image: url('../../images/logo.svg');
      width: calc($top-nav-bar-h * 0.8);
      height: calc($top-nav-bar-h * 0.8);
    }

    @media screen and (min-width: $menu-break-point) {
      display: flex;
    }

    .navbar-item{
      // text-transform: uppercase;
      height: $top-nav-bar-h;
      white-space: nowrap;
      &.active{
        border-bottom: 2px solid white;
      }
    }
  }

  #main-menu {
    // background-color: rgba(255,255,255,0.2);
    background-color: rgba(55,55,55,0.6);
    backdrop-filter: blur(12px);
    border: 1px solid white;
    z-index: 401;
    position: fixed;
    color: white;
    overflow-x: hidden;
    overflow-y: auto;
    padding: calc(2 * $menu-margins) 0;
    
    top: calc($top-nav-bar-h - (2 * $menu-margins));
    // top: $menu-margins;
    left: $menu-margins; right: $menu-margins; bottom: $menu-margins;
    display: flex;
    flex-flow: column nowrap;

    .navbar-item{
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      &.active{
        border-left: 3px solid white;
      }
    }
    @media screen and (min-width: 500px) {
      row-gap: $mp4;
      .navbar-item{
        height: 6vw;
        font-size: 5vw;
      }
    }
    @media screen and (min-width: $break-point-800) {
      row-gap: 0;
      .navbar-item{
        font-size: 1.2em;
      }
    }
  }


  $pt-margin: calc($top-nav-bar-h * 0.1);
  $pt-height: calc($top-nav-bar-h);
  .popper-opener {
    z-index: 402;
    // background-color: rgba(255, 0, 0, 0.7);
    background-color: transparent;
    height: calc($top-nav-bar-h + 50px);
    width: 300px;
    position: fixed;
    right: 0;
    top: 0;
    pointer-events: stroke;
    @media screen and (min-width: $menu-break-point) {
      display: none;
    }
    &.full-menu-open{ display: none; }
  }
  .popper-tray {
    z-index: 402;
    // background-color: rgba(255, 0, 0, 0.7);
    background-color: transparent;
    height: 100vh;
    position: fixed;
    left: 0; right: 0;
    top: 0;
    pointer-events: none;

    padding: $pt-margin;
    display: flex;
    flex-flow: row-reverse wrap;
    // align-items: flex-start;
    align-content: flex-start;
    // justify-content: flex-start;
    gap: $pt-margin;
    transition: top $menu-become-visible-duration ease-out;
    &.open{
      justify-content: space-between;
    }
    &.hide{
      &:not(.open) {
        top: calc(-1 * $pt-height);
        transition: top $menu-become-invisible-duration ease-in;
      }
    }
  }
  .popper {
    z-index: 403;
    pointer-events: all;
    // top: calc($top-nav-bar-h * 0.1);
    width: calc($top-nav-bar-h * 0.8);
    height: calc($top-nav-bar-h * 0.8);
    // margin: 0 $pt-margin;

    font-size: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
  }
  .circular-btn {
    border-radius: 50%;
    filter: drop-shadow(2px 3px 6px #000);
    &:hover{
      cursor: pointer;
      filter: drop-shadow(3px 4px 8px #000);
    }
    &:active{
      filter: drop-shadow(1px 1px 2px #000);
    }
  }

  #menu-popper {
    // right: calc($top-nav-bar-h * 0.1);
    color: white;
    // position: sticky;
    // top: calc($top-nav-bar-h * -1);

    @media screen and (min-width: $menu-break-point) {
      display: none;
    }
    &:not(.open) { background: rgba(255, 56, 45, 0.65); }
    &.open{ 
      color: #333;
      background-color: rgba(215,215,215,1.0);
    }
  }

  #support-popper {
    // right: calc($top-nav-bar-h * 0.1);
    // right: calc(($top-nav-bar-h * 0.8) + (($top-nav-bar-h * 0.1) * 2));
    // top: calc($top-nav-bar-h);
    color: $support;
    background-color: rgba(125,125,125,0.3);

    // @media screen and (min-width: $menu-break-point) {
    //   display: none;
    // }
    @media screen and (min-width: $menu-break-point) {
      position: fixed;
      right: calc($top-nav-bar-h * 0.1);
      top: calc($top-nav-bar-h + 10px);
    }
    &.open{ 
      display: none;
    }
    &:hover{
      background-color: rgba(225,225,225,0.3);
    }
  }

  #audio-popper {
    background-color: rgba(255,255,255,0.8);
    color: #333;
    &.open{
      justify-self: flex-end;
    }
    // @media screen and (max-width: $menu-break-point) {
    //   // &:not(.open){
    //   //   right: calc((($top-nav-bar-h * 0.8) * 2) + (($top-nav-bar-h * 0.1) * 3));
    //   // }
    //   // top: calc($top-nav-bar-h + ($top-nav-bar-h * 0.8) + ($top-nav-bar-h * 0.1));
    // }
    @media screen and (min-width: $menu-break-point) {
      position: fixed;
      right: calc(($top-nav-bar-h * 0.1) + $top-nav-bar-h);
      top: calc($top-nav-bar-h + 10px);
    }
  }
}