// Font sizes
$fontSize1: 16px;
$fontSize2: 18px;
$fontSize3: 20px;
$fontSize4: 22px;
$fontSize5: 24px;
$fontSize6: 26px;
$dFontSize1: 1.5vw;

$bodySize1: 16px;
$h1Size1: 1.6em;
$h1Size2: 2.0em;
$h1Size3: 2vw;
$h2Size3: 1.8vw;
$h3Size3: 1.6vw;
$pSize1: 1.2em;
$pSize2: 1.3em;
$pSize3: 1.4vw;
$pSize4: 1.6vw;
$pSize5: 1.8vw;
$pSize6: 2.0vw;
$aSize2: 1.8vw;
  // Dynamic
$pDSize1: 1.2vw;
$pDSize2: 5vw;
$pDSize3: 3.5vw;

// Layout
$min-site-width: 360px;
$menu-height1: 80px;
$menu-activator-height: 200px;
$spinner-loader-size: 100px;
$break-point-1: $min-site-width;
$break-point-400: 400px;
$break-point-530: 530px;
$break-point-800: 800px;
$break-point-1024: 1024px;
$break-point-1400: 1400px;
$break-point-1920: 1920px;
$break-point-2500: 2500px;

$border-padding1: 1rem;
$border-padding2: 1.5rem;
$border-padding3: 2rem;

// Margin-Padding
$mp1: 3px;
$mp2: 6px;
$mp3: 12px;
$mp4: 18px;
$mp5: 24px;
$mp6: 32px;

// Colors
$primary-text: #ffd6bd;
$white-text: #fff;
$gray-text: #ccc;
$off-white-yellow: #ffd6bd;
$header-red: #da372e;
$header-orange: #ff804a;
$really-dark-purple-1: #1d0b19;
$dark-purple-1: #590d21;
$dark-purple-2: #4f092d;
$dark-purple-3: #6e062a;
$dark-purple-2a: rgba(89, 14, 34, 0.9);
$red-purple-1: #9c232e;
$red-purple-2: #920a0b;
  // Social Media
  $facebook: #4064ac;
  $instagram: #ec40ac;
  $instagram2: #8a3ab9;
  $twitter: #1DA1F2;
  $support: #ffc446;
  $support_green: #5ad07d;
  $email: $primary-text;
  $website: white;
  $share: white;
  $script: rgb(253, 216, 161);

// Transitions
$menu-become-visible-duration: 200ms;
$menu-become-invisible-duration: 500ms;
@keyframes fadeIn {
  0% { display: none; opacity: 0; }
  1% { display: flex; opacity: 0; }
  100% { display: flex; opacity: 1; }
}
@keyframes fadeOut {
  0% { display: flex; opacity: 1; }
  99% { display: flex; opacity: 0; }
  100% { display: none; opacity: 0; }
}
@-webkit-keyframes easeOutBounce {
  0% {width: 50px; -webkit-animation-timing-function: ease-in;}
  33% {width: 250px; -webkit-animation-timing-function: ease-out;}
  50% {width: 210px; -webkit-animation-timing-function: ease-in;}
  66% {width: 250px; -webkit-animation-timing-function: ease-out;}
  82% {width: 240px; -webkit-animation-timing-function: ease-in;}
  92% {width: 250px; -webkit-animation-timing-function: ease-out;}
  97% {width: 245px; -webkit-animation-timing-function: ease-in;}
  100% { width: 250px;}
}