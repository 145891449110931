@import "../../variables";

#contact-component {
  background-image: url('../../images/backgrounds/pg2.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .bg-overlay{
    background: rgba(0,0,0,0.8);
    // background: linear-gradient(180deg, $dark-purple-3, rgba(0,0,0,0.7) 2px);
    background: linear-gradient(180deg, $dark-purple-3, rgba(0,0,0,0.6) 2px);
    // backdrop-filter: blur(2px);
  }

  .vertical-sorter {
    height: 100vh;
    min-height: 900px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  color: $primary-text;

  .header {
    margin: 2em 0 2em;
    color: white;
  }
  .main-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    padding: 0 2em;
    column-gap: 8%;
    a { color: $header-red; }
    .col{
      min-width: 300px;
    }
    @media screen and (min-width: $break-point-800) {
      justify-content: space-evenly;
    }
  }
  .spacer-container {
    background: url("../../images/pages/footer/Runes.svg") no-repeat;
    background-position: center 85%;
    background-size: contain;
    background-size: 30vh;
    height: 100vh;
  }
  .sub-container{
    height: 115px;
    position: relative;
    // padding-bottom: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    .footer-text{
      position: absolute;
      bottom: 1.5em;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      gap: 0.4em;
      &>a:hover{ color: $header-red;}
    }
  }
  .footer-items{
    &>.footer-item {
      margin-bottom: 1em;
      h3{ margin-bottom: 0.5em; }
      &>*>svg{
        vertical-align: middle;
        margin-right: 0.5em;
      }
      &.instagram>a>svg{
        color: $instagram2;
      }
      &.twitter>a>svg{
        color: $twitter;
      }
      &.instagram>a>svg{
        color: $instagram2;
      }
      &.email>a>svg{
        color: $email;
      }
      &.facebook>a>svg{
        color: $facebook;
      }
      &.website>a>svg{
        color: $website;
      }
      &.share>a>svg{
        color: $share;
      }
      &.script>a>svg{
        color: $script;
      }
      &.sharing>a>svg{
        color: $twitter;
      }
      &.seed-and-spark>a{
        color: $support;
        &>svg, &>.greenlit{
          color: $support_green;
        }
      }
      &.business-plan>a>svg{
        color: #85b55a;
      }
      >a {
        display: flex;
        align-items: center;
      }
      &.resource-item{
        >a>svg{
          margin-left: $mp2;
        }
      }
    }
  }
  #logo-btn{
    display: inline-block;
    background-image: url('../../images/logo.svg');
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
  }
}