@import "../../variables.scss";

#team-component {
  background: $dark-purple-2;
  background: radial-gradient(ellipse, $dark-purple-3 60%, $really-dark-purple-1);

  background-image: url('../../images/backgrounds/pg5.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  .bg-overlay{
    background: radial-gradient(circle, rgba(110, 6, 42, 0.2) 60%, rgba(29, 11, 25, 0.9));
    // backdrop-filter: blur(8px);
  }

  color: white;

  h2, h4{
    text-transform: uppercase;
  }
  // h2{ font-size: 1.2em }
  h4{
    // font-size: 0.7em;
    // font-style: italic;
    margin: 0.5em 0;
  }

  .members {
    display: flex;
    flex-flow: row wrap;
    // gap: 0.5em;
    row-gap: 1em;
    .member {
      // padding: 0 0 1em 0;
      // max-width: 50vw;
      // flex-basis: 50%;
      img {
        margin-right: 1rem;
        max-width: 50%;
      }
      // .member-name{
      //   margin-bottom: $mp1;
      // }
      .member-role{
        margin-bottom: $mp3;
      }
    }
  }

  @media screen and (min-width: $break-point-400) {
    .members {
      row-gap: 2em;
    }
  }
  @media screen and (min-width: $break-point-800) {
    .members{
      flex-flow: row wrap;
      column-gap: 6%;
      .member {
        flex-basis: 47%;
        img { max-width: 40%; margin: 0 1em 1em 0; }
        // &.antonio{ order: 3; }
        // &.aaron{ order: 4; }
        // &.josh{ order: 5; }
      }
    }
  }

  @media screen and (min-width: $break-point-1024) {
    .members{
      column-gap: 2%;
      row-gap: 1.5em;
      .member {
        flex-basis: 32%;
        img { max-width: 35%; }
      }
    }
  }

  @media screen and (min-width: $break-point-1400) {
    .members{
      column-gap: 6%;
      .member {
        flex-basis: 47%;
        img { max-width: 50%; }
        // &.josh,&.antonio,&.aaron{ order: unset; }
      }
    }
  }
}