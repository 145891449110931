@import "../../variables";

#sound-component {
  background: $dark-purple-1;
  background: radial-gradient(circle, $dark-purple-1 80%, $red-purple-2 94%, #af3737 100%);
  background-image: url('../../images/backgrounds/pg12.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .bg-overlay{
    background: radial-gradient(circle, rgba(89, 13, 33, 0.5) 80%, rgba(146, 10, 11, 0.7) 94%, rgba(175, 55, 55, 0.9) 100%);
    // backdrop-filter: blur(8px);
  }

  color: $white-text;

  .audio-player-container {
    text-align: center;
    margin-bottom: $mp6;
  }
  audio {
    height: 34px;
    // filter: opacity(75%);
  }
  .contributor{
    margin-bottom: $mp6;
    .bio-img{
      &>img { width: 100%; }
    }
  }
  .artist,.role{
    text-transform: uppercase;
    margin-bottom: $mp1;
  }
  .role{
    margin-bottom: $mp2;
    &.role-lg { display: none; }
  }
  .links {
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
    .bullet {
      font-size: 1em;
    }
    a { color: $header-red; }
  }
  .description p {
    padding-bottom: 0.5em;
  }

  @media screen and (min-width: $break-point-800) {
    // background: radial-gradient(circle, $dark-purple-1 40%, $red-purple-2 80%, #c54f4f 100%);
    // .bg-overlay{
    //   background: radial-gradient(circle, rgba(89, 13, 33, 0.5) 80%, rgba(146, 10, 11, 0.7) 94%, rgba(175, 55, 55, 0.9) 100%);
    //   // backdrop-filter: blur(8px);
    // }
    .contributor {
      display: grid;
      grid-template-columns: 1fr 1.2fr;
      grid-auto-rows: auto auto;
      grid-template-areas:
        "artist role"
        "left right"
        "left links"
      ;
      grid-gap: 0.6em;
      column-gap: 2em;
      align-items: start;
      .artist{
        grid-area: artist;
        text-align: right;
      }
      .bio-img{ grid-area: left; }
      .role{
        grid-area: role;
        &.role-sm { display: none; }
        &.role-lg { display: inline-block; }
      }
      .artist, .role {
        margin-bottom: $mp2;
      }
      .description{ grid-area: right; }
      .links{ 
        grid-area: links;
        column-gap: 0.5rem;
      }
      .artist,.role,.links { align-self: end; }
    }
    .links {
      display: block;
      &>*{ margin-right: $mp3; }
      .bullet {
        font-size: 1.8vw;
      }
    }
  }

  @media screen and (min-width: $break-point-1400) {
    .contributor {
      grid-template-columns: 1fr 1.8fr;
      // margin-bottom: unset;
    }
    .artist, .role{
      margin-bottom: $mp2;
    }
  }
}