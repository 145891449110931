@import "../../variables";

#synopsis-component {
  background: $dark-purple-2;
  background: linear-gradient(0deg, $dark-purple-3 50%, $dark-purple-2);

  background-image: url('../../images/backgrounds/pg3.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .bg-overlay{
    background: linear-gradient(0deg, rgba(110, 6, 42, 0.5) 50%, $dark-purple-2);
    // backdrop-filter: blur(8px);
  }

  color: $primary-text;

  .logline{
    margin-top: $mp5;
  }
  h3>a{
    color: $header-red;
    & > svg{
      color: $script;
      vertical-align: text-bottom;
    }
  }

  @media screen and (min-width: $break-point-800) {
    background-position: center;
  }
}