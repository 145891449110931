@import "../../variables";

#fans-component {
  $poster-margin: 30px;
  color: $primary-text;
  background: $dark-purple-3;
  background: radial-gradient(ellipse, #c00d0d 0%, $dark-purple-3 60%, #460721 100%);
  .is-for {
    color: $white-text;
    margin-left: $mp5;
    // font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 3px;
  }

  .posters-container{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5em;
    &>.poster{
      margin: 5px 0;
      width: 45%;
      flex-basis: 48%;
      >img{width: 100%;}
    }
  }

  @media screen and (min-width: 600px) {
    h1{ display: inline-block; }
    .posters-container{ margin-top: 1em; }
  }
  @media screen and (min-width: 465px) {
    .posters-container{
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-evenly;
      &>.poster{
        margin: calc($poster-margin/2) calc($poster-margin/4);
        flex-basis: unset;
        width: auto;
      }
    }
  }
  @media screen and (min-width: $break-point-800) {
    .poster { margin: $poster-margin calc($poster-margin/2); }
    .poster.even{ margin-top: calc($poster-margin * 2); }
  }
}