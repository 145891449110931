@import "../../variables";

#location-component {
  .page-container{
    padding: 1em;
    background: #ddb9a4;

    background-color: rgba(13, 1, 2, 0.9);
    background-image: url('../../images/backgrounds/pg7a.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    filter: drop-shadow(0 4px 10px #555);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    column-gap: 2em;
    
    align-items: center;
    // &>*{
    //   &:not(:first-child) {
    //     padding-left: 0.5em;
    //   }
    //   &:not(:last-child) {
    //     padding-right: 0.5em;
    //   }
    // }
  }
  .arch-img {
    &>img{ width: 100%; }
  }

  // @media screen and (min-width: 876px) {
  //   .page-container{ justify-content: space-between; }
  // }

  // @media screen and (min-width: 1161px) {
  //   .upper-segment>*:last-child{margin-top: 0;}
  // }
}